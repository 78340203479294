import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger"
import {SplitText} from "gsap/SplitText";

const initializeScrollAnimations: () => [split_texts: SplitText[]] = () => {

    gsap.registerPlugin(ScrollTrigger)

    ScrollTrigger.config({
        autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
    })

    ScrollTrigger.getAll().forEach(t => t.kill());
    let split_texts: Record<string, SplitText> = {}

    document.querySelectorAll(`[data-scroll-group="true"]`).forEach((group) => {
        // Scroll Scrubbing & Parallax
        group.querySelectorAll(`[data-scroll-parallax="true"]`).forEach(el => {
            gsap.to(el, {
                x: el.getAttribute("data-scroll-x") ?? 0,
                scaleX: el.getAttribute("data-scroll-scale-x") ?? undefined,
                y: el.getAttribute("data-scroll-y") ?? 0,
                yPercent: el.getAttribute("data-scroll-y-percent") ?? 0,
                ease: el.getAttribute("data-scroll-ease") ?? "none",
                scrollTrigger: {
                    trigger: group,
                    start: el.getAttribute("data-scroll-start") ?? 'bottom top',
                    end: el.getAttribute("data-scroll-end") ?? 'top bottom',
                    invalidateOnRefresh: true,
                    scrub: parseInt(el.getAttribute("data-scroll-scrub") ?? "1") ?? 1
                }
            });
        });

        // Clip Mask Animation with Scroll Scrubbing
        group.querySelectorAll(`[data-scroll-mask-reveal="true"]`).forEach(el => {
            gsap.to(el, {
                clipPath: el.getAttribute("data-scroll-clip-path") ?? 0,
                ease: el.getAttribute("data-scroll-ease") ?? "none",
                scrollTrigger: {
                    trigger: group,
                    start: el.getAttribute("data-scroll-start") ?? 'bottom top',
                    end: el.getAttribute("data-scroll-end") ?? 'top bottom',
                    invalidateOnRefresh: true,
                    scrub: parseInt(el.getAttribute("data-scroll-scrub") ?? "1") ?? 1
                }
            });
        });
    });

    // Animate In once in View
    document.querySelectorAll(`[data-reveal-group="true"]`).forEach((group, k_index) => {
        group.querySelectorAll(`[data-reveal="true"]`).forEach((element, index) => {
            switch (element.getAttribute("data-object")) {
                case "main_text":

                    let wl_split: SplitText;
                    if (split_texts[`${k_index}_${index}`]) {
                        wl_split = split_texts[`${k_index}_${index}`];
                    } else {
                        wl_split = new SplitText(element, {
                            type: "words,lines",
                            linesClass: 'line_container'
                        });
                        split_texts[`${k_index}_${index}`] = wl_split;
                    }
                    gsap.set(wl_split.words, {
                        'will-change': 'opacity, transform',
                        transformStyle: 'preserve-3d',
                        transformOrigin: '0% 100%',
                        perspective: 1000,
                        rotateZ: 4,
                        yPercent: 120,
                        autoAlpha: 1,
                    })
                    ScrollTrigger.create({
                        trigger: group,
                        scrub: true,
                        start: 'top 80%',
                        onEnter: () => {
                            gsap.to([element,wl_split.words],
                                {
                                    delay: index * 0.2,
                                    yPercent: 0,
                                    rotateZ: 0,
                                    overwrite: 'auto',
                                    autoAlpha: 1,
                                    duration: 1,
                                    ease: 'power3',
                                    stagger: parseFloat(element.getAttribute("data-stagger") ?? "0.05")
                                })
                        }
                    });
                    break;
                case "simple":
                    ScrollTrigger.create({
                        trigger: group,
                        scrub: true,
                        start: 'top 80%',
                        onEnter: () => {
                            gsap.to(element,
                                {
                                    x: 0,
                                    y: 0,
                                    autoAlpha: 1,
                                    overwrite: 'auto',
                                    duration: 0.7,
                                    ease: "expo",
                                    stagger: parseFloat(element.getAttribute("data-stagger") ?? "0")
                                })
                        }
                    });
                    break;
            }
        })
    })

    return [Object.keys(split_texts).map(k => split_texts[k])];

}

export default initializeScrollAnimations;