import React from "react";
import type {GatsbyBrowser} from "gatsby";
import Layout from "./src/components/layout/layout";
import "./src/styles/global.css";
import "./src/styles/fonts.css";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element}) => {

    return (
        <Layout>
            {element}
        </Layout>
    )

}