import React, {useEffect} from "react";
import {ReactLenis} from "@studio-freight/react-lenis";
import NavigationBar from "../navigation-bar/navigation-bar";
import {useLocation} from "@gatsbyjs/reach-router"
import initializeScrollAnimations from "../scroll-animations/scroll-animations";

const Layout = ({children}: {children: React.ReactNode}) => {

    const location = useLocation();

    useEffect(() => {
        let split_texts: SplitText[] = [];
        const [s] = initializeScrollAnimations();
        split_texts = s;

        return () => {
            split_texts.forEach(st => {
                st.revert();
            })
        }

    }, [location.pathname]);

    return (
        <>
            <ReactLenis root lerp={0.2} >
                {/*<NavigationBar/>*/}
                <main className={'z-30'}>
                    {children}
                </main>
            </ReactLenis>
        </>
    )

}

export default Layout;